import { useEffect, useState, useContext, useCallback } from "react"
import '../../styles/css/towingPayload-vehicleFilter.css';
import { FilterMenu } from './towingPayload-vehicleFilterMenu';
import { TrimCard } from './components/towingPayload-trimCard';
import * as analytics from './towingPayload-analytics';
import AppSettings from '../../appsettings';
import { PopupTooltipContext } from '../../components/contexts/popupTooltipContext';
import { getS3PathName } from "../utils/vehicleName";
import { useHistory } from 'react-router-dom';

export const VehicleFilter = (props) => {
    const [allVehicleInformation,setAllVehicleInformation] = useState(null)
    const [filteredVehicles,setFilteredVehicles] = useState(null)
    const [filterChange,setFilterChange] = useState(null);
    const [filterOptions,setFilterOptions] = useState(null);
    const [vehiclesChange,setVehiclesChange] = useState(null);
    const [displayedTrims,setDisplayedTrims] = useState([]);
    const [resetData,setResetData] = useState(false);
    const [showFeatured, setShowFeatured] = useState(true)
    const [s3ModelNamePath, setS3ModelNamePath] = useState('');
    var allModelInformation = {};
    const history = useHistory();

    const { visiblePopupTooltip, setVisiblePopupTooltip, setCurrentPopupTooltip } = useContext(PopupTooltipContext);

    const getS3ModelNamePath = useCallback(async () => {
        const { modelName, modelYear } = props.vehicleSelection;
        const s3PathName = await getS3PathName(modelName);
        setS3ModelNamePath(AppSettings.AWSImgRepo.vehicle + modelYear + '/' + s3PathName + '/en/');
    }, [props.vehicleSelection])

    useEffect(() => {
        getS3ModelNamePath();
    }, [getS3ModelNamePath])

    useEffect(() => {
        props.pageLoadAnalytics && analytics.vehicleFilterLoaded(props.pageLoadAnalytics, props.vehicleSelection);
    }, []);
    useEffect( () =>{
        arrangeIncomingVehicleData()
        setFilterOptionsData(allModelInformation)
    },[]);
    useEffect( () =>{
        const yearModelCodes = Object.keys(props.driveInfo)
        const hasAllModels = props.topModelCodes.every(v => yearModelCodes.includes(v));
    
        allVehicleInformation && setDisplayedTrimsData(hasAllModels ? props.topModelCodes : yearModelCodes.splice(0, 3))
    },[allVehicleInformation]);
    
    useEffect( () =>{
        if (filterChange){
            setVehiclesChange(true)
            modifyFilteredVehicles()
        }
    },[filterChange]);

    useEffect( () =>{
        if (filteredVehicles){
            filteredVehicles && setFilterOptionsData(filteredVehicles)
        }      
    },[vehiclesChange,filteredVehicles]);
    useEffect( () =>{
        if (resetData){
            allVehicleInformation && setFilteredVehicles(allVehicleInformation)
            allVehicleInformation && setFilterOptionsData(allVehicleInformation)
            props.topModelCode && setDisplayedTrimsData(props.topModelCodes)
            setResetData(false)
        }   
    },[resetData]);

    const setDisplayedTrimsData = async (modelCodes) =>{
        var popularTrims = []
        modelCodes.forEach(function(x,index){
         popularTrims[index] = allVehicleInformation[x]
        })
        setDisplayedTrims(popularTrims) 
    }
    const modifyFilteredVehicles = ()=>{
        let newFilteredVehicles = {}
        for (var x in filteredVehicles){
            if(filteredVehicles[x][filterChange.key] ==filterChange.value){
                newFilteredVehicles[x] = filteredVehicles[x]
            }
        }
        setFilteredVehicles(newFilteredVehicles)
        
        setDisplayedTrimsData(Object.keys(newFilteredVehicles))
   }
    const arrangeIncomingVehicleData= async () =>{
       
        Object.keys(props.towingInfo).forEach (element  => {
         if (element != 'Description'){allModelInformation[element] = {} }
        });
        orderWeightData()
        orderTowingData()
        orderTrimData()
        orderDriveTypeData()
        orderCabData()
        orderBedData()
        setAllVehicleInformation(allModelInformation)
        setFilteredVehicles(allModelInformation)
        props.setModelCodeVehicleData(allModelInformation);
    }
   
    const orderWeightData = async () =>{
       var key, value = null
       for (var x in props.weightInfo){
           if (props.weightInfo[x].Description.toLowerCase().includes('curb weight')){
            key = 'curbWeight'
            value = props.weightInfo[x]
            setDataEntry(key,value)
           }
           if (props.weightInfo[x].Description.toLowerCase().includes('payload')){
            key = 'payloadCapacity'
            value = props.weightInfo[x]
            setDataEntry(key,value)
           }
           if (props.weightInfo[x].Description.toLowerCase().includes('gvwr')){
            key = 'GVWR'
            value = props.weightInfo[x]
            setDataEntry(key,value)
           }
           if (props.weightInfo[x].Description.toLowerCase().includes('gcwr')){
            key = 'GCWR'
            value = props.weightInfo[x]
            setDataEntry(key,value)
           }
        }
    }
    const orderTowingData = () =>{
        var key, value = null

        for (var x in props.towingInfo){
            if (!x.includes('Description')){
                key = 'towingCapacity'
                value = props.towingInfo
                setDataEntry(key,value) 
            }
            
        }
    }
    const orderTrimData = () =>{
        for (var x in props.trimInfo){
            allModelInformation[props.trimInfo[x]['modelCode']]['modelCode'] = props.trimInfo[x]['modelCode']
            allModelInformation[props.trimInfo[x]['modelCode']]['engine'] = props.trimInfo[x]['engine']
            allModelInformation[props.trimInfo[x]['modelCode']]['MSRP'] = props.trimInfo[x]['msrp']
            allModelInformation[props.trimInfo[x]['modelCode']]['filterImage'] = props.trimInfo[x]['trimImageFilename']
            allModelInformation[props.trimInfo[x]['modelCode']]['trimName'] = props.trimInfo[x]['name']
            allModelInformation[props.trimInfo[x]['modelCode']]['fullName'] = props.trimInfo[x]['fullName'].replace(props.trimInfo[x]['name'],'')
            allModelInformation[props.trimInfo[x]['modelCode']]['fullName'] = allModelInformation[props.trimInfo[x]['modelCode']]['fullName'].replace(/[()]/g,'')
        }
    }
    const orderDriveTypeData = ()=>{
        for (var x in props.driveTypes){
            for(var item in props.driveInfo){
                if (props.driveInfo[item].includes(props.driveTypes[x].driveType)){
                    allModelInformation[item]['driveType'] = props.driveTypes[x].driveType
                }
            }
        }
    }
    const orderCabData = ()=>{
        for (var x in props.cabList){
            for(var item in allModelInformation){
                if (allModelInformation[item].fullName && allModelInformation[item].fullName.includes(props.cabList[x].cab)){
                    allModelInformation[item]['cab'] = props.cabList[x].cab
                }
            }
        }
    }
    const orderBedData = ()=>{
        for (var x in props.bedLengths){
            for(var item in allModelInformation){
                if (allModelInformation[item].fullName && allModelInformation[item].fullName.includes(props.bedLengths[x].bedLength)){
                    allModelInformation[item]['bedLength'] = props.bedLengths[x].bedLength
                }
            }
        }
    }
    const setDataEntry = (key,value)=>{
        for (var x in allModelInformation){
            allModelInformation[x][key] = value[x]
        }
    }

    const handleNavClick = (item) => {
        props.setTrimSelection(item);
        props.setPage("Calculator");
        props.setCalculatorType("Expert");
        history.push(`/towingPayload/${props.vehicleSelection.modelName}/${props.vehicleSelection.modelYear}/${item?.modelCode}`)
        props.clickAnalytics && analytics.beginCalculatingClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, item);
    };

    const setFilterOptionsData =  (dataToFilter) =>{
        let filterItems = {}
        var cab = new Set(), bedLength = new Set(), driveType = new Set()
        var engine = new Set(), trimName = new Set()
        for (var x in dataToFilter){
            dataToFilter[x].cab && cab.add(dataToFilter[x].cab)
            dataToFilter[x].bedLength && bedLength.add(dataToFilter[x].bedLength)
            dataToFilter[x].driveType && driveType.add(dataToFilter[x].driveType);
            dataToFilter[x].engine && engine.add(dataToFilter[x].engine)
            dataToFilter[x].trimName && trimName.add(dataToFilter[x].trimName)
        }
        filterItems['modelCode'] = Object.keys(dataToFilter)
        filterItems['cab'] = Array.from(cab)
        filterItems['bedLength'] = Array.from(bedLength)
        filterItems['driveType'] = Array.from(driveType)
        filterItems['engine'] = Array.from(engine)
        filterItems['trimName'] = Array.from(trimName)
        setFilterOptions(filterItems)
   }

    const filterBody = displayedTrims.filter(item => item != null).slice(0,3).map((item, index) =>(
        <TrimCard
            item = {item}
            index = {index}
            vehicleImage = {s3ModelNamePath + item.filterImage}
            termPopUps = {true}
            vehicleSelection = {props.vehicleSelection}
            handleNavClick = {handleNavClick}
            setVisiblePopUp = {setVisiblePopupTooltip}
            visiblePopUp = {visiblePopupTooltip}
            clickAnalytics={props.clickAnalytics}
            pageLoadAnalytics={props.pageLoadAnalytics}
            infoPopUpData={props.infoPopUpData}
        />
    ));

   return <div className = "tp-vehicleFilter">
       <div><span className = "tp-vehicleFilterYear">{props.vehicleSelection.modelYear}</span>
            <span className = "tp-vehicleFilterModel">{props.vehicleSelection.modelName}</span>
            <div className = "tp-vehicleFilterNotice">Use the filters below to narrow down to a specific vehicle, then click the button on the card to begin calculating.</div>
        </div>
        <div className = "tp-vehicleFilterBody">
            {filterOptions && <div>
                <FilterMenu
                    filterOptions = {filterOptions}
                    setFilterChange = {setFilterChange}
                    setResetData = {setResetData}
                    resetData = {resetData}
                    imagePath={props.imagePath}
                    showFeatured = {setShowFeatured}
                    clickAnalytics={props.clickAnalytics}
                    vehicle={props.vehicleSelection}
            /></div>}
            <div className="tp-vehicleFilterVehicleSection">
                {<div className="tp-vehicle-FilterFeatured" style={{visibility: showFeatured ? 'visible' : 'hidden'}}>FEATURED</div>}
                <div className="tp-vehicleFilterVehicles">{filterBody}</div>
            </div>
        </div>
    </div> 
}