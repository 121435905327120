import { Component } from 'react';
import { getArticle, putArticle } from '../../services/articlesService.js';
import { deleteComment, getComment, getComments, postComment, postReply, putComment } from '../../services/commentsService.js';
import { getSearchResults } from '../../services/elasticsearchService';
import { getLocale } from '../../services/languageTranslationService.js';
import { postViewArticleAction, postSubmitArticleCommentAction } from '../../services/nitroService';
import getPdf from '../../services/pdfGeneratorService.js';
import { postUserActivityRecord } from '../../services/userActivityService.js';
import '../../styles/css/article.css';
import applyMarkdown from '../utils/applyMarkdown';
import { ArticleTemplate } from './article.tpl';
import getMisc from "../../services/miscService";


const analyticsFunctions = require('./article.analytics.js');

const { $ } = window;

export default class ArticleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleImage: '',
      article: null,
      textBoxInput: '',
      replyTextBoxInput: '',
      commentError: false,
      replyError: false,
      files: '',
      relatedContent: [],
      description: '',
      minCommentLength: 0,
      commentErrorMsg: '',
    };
    this.submitCommentClick = this.submitCommentClick.bind(this);
    this.showMoreComments = this.showMoreComments.bind(this);
    this.clickDownload = this.clickDownload.bind(this);
    this.addReplyClick = this.addReplyClick.bind(this);
    this.activeDeleteClick = this.activeDeleteClick.bind(this);
    this.activeUnpromoteClick = this.activeUnpromoteClick.bind(this);
    this.activePromoteClick = this.activePromoteClick.bind(this);
    this.deleteCommentClick = this.deleteCommentClick.bind(this);
    this.unpromoteCommentClick = this.unpromoteCommentClick.bind(this);
    this.promoteCommentClick = this.promoteCommentClick.bind(this);
    this.submitReplyClick = this.submitReplyClick.bind(this);
    this.printArticle = this.printArticle.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.getRelatedArticles = this.getRelatedArticles.bind(this);
    this.getRelatedContent = this.getRelatedContent.bind(this);
    this.getCleanedArticleId = this.getCleanedArticleId.bind(this);

    // Bind scope of 'this' to helper analytics funcitons
    this.sendVideoEventAnalytics = analyticsFunctions.sendVideoEventAnalytics.bind(this);
    this.sendActionAnalytics = analyticsFunctions.sendActionAnalytics.bind(this);
    this.sendClickAnalytics = analyticsFunctions.sendClickAnalytics.bind(this);
    this.sendArticleContentLinkClickAnalytics = analyticsFunctions.sendArticleContentLinkClickAnalytics.bind(this);
    this.sendLoadAnayltics = analyticsFunctions.sendLoadAnayltics.bind(this);
    this.sendRelatedClickAnalytics = analyticsFunctions.sendRelatedClickAnalytics.bind(this);
  }

  clickDownload(id) {
    this.sendActionAnalytics('Share', 'Download');
    return new Promise((resolve, reject) => {
      getPdf(id)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  printArticle() {
    window.print();
    this.sendActionAnalytics('Share', 'Print');
  }

  getRelatedArticles(articleId) {
    const articleText = articleId.replace(/_/g, ' ');
    getSearchResults('*', articleText, 0, 4)
      .then((searchItems) => {
        if (searchItems && searchItems.hits) {
          const relatedContent = this.getRelatedContent(searchItems.hits.hits, articleId);
          this.setState({
            relatedContent,
          });
        }
      });
  }

  getMinCommentLength(articleId) {
    getMisc('minCommentLengthArticle','en')
      .then((data) => {
        const minCommentLength = data?.items?.articles.find(article => article.articleID === articleId)?.minCharacters;
        if(minCommentLength){
          this.setState({
            minCommentLength,
          });
        }
      })
      .catch((error) => {
        console.log(`ERROR: ${error}`);
      });
  }

  loadArticles(articleId) {
    return new Promise((resolve, reject) => {
      getArticle(articleId)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  loadComments(articleId, lastKey) {
    return new Promise((resolve, reject) => {
      getComments(articleId, lastKey)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  addComment(articleId, comment) {
    return new Promise((resolve, reject) => {
      postComment(articleId, comment)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  updateComment(articleId, comment) {
    return new Promise((resolve, reject) => {
      putComment(articleId, comment)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  updateArticle(article) {
    return new Promise((resolve, reject) => {
      putArticle(article)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  addReply(articleId, timestamp, reply) {
    return new Promise((resolve, reject) => {
      postReply(articleId, timestamp, reply)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  deleteComment(articleId, timestamp) {
    return new Promise((resolve, reject) => {
      deleteComment(articleId, timestamp)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  compareTimestamp(a, b) {
    const dateA = Date.parse(a.createdTimestamp);
    const dateB = Date.parse(b.createdTimestamp);
    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  }

  comparePromotedTimestamp(a, b) {
    const dateA = Date.parse(a.promotedDate);
    const dateB = Date.parse(b.promotedDate);
    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  }

  getIndex(timestamp) {
    for (let i = 0; i < this.state.comments.length; i++) {
      if (this.state.comments[i].createdTimestamp === timestamp) {
        return i;
      }
    }
  }

  getPromotedIndex(timestamp) {
    for (let i = 0; i < this.state.promotedComments.length; i++) {
      if (this.state.promotedComments[i].createdTimestamp === timestamp) {
        return i;
      }
    }
  }

  showMoreComments() {
    this.loadComments(this.state.id, this.state.lastKey)
      .then((value) => {
        const lastKey = value.LastEvaluatedKey;
        const comments = this.state.comments.concat(value.Items);
        comments.sort(this.compareTimestamp);
        this.setState({
          comments,
          lastKey,
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  addReplyClick(comment) {
    comment.activeReply = !comment.activeReply;

    const index = this.getIndex(comment.createdTimestamp);
    const { comments } = this.state;
    comments[index] = comment;

    this.setState({
      comments,
    });
  }

  getRelatedContent(searchItems, articleId) {
    const relatedContent = [];
    for (const item in searchItems) {
      if (searchItems[item]._source.id !== articleId) {
        relatedContent.push(searchItems[item]._source);
      }
    }

    const relatedContentList = relatedContent.slice(0, 3);

    for (const content in relatedContentList) {
      relatedContentList[content].description = this.formatDescription(relatedContentList[content].description);
    }

    return relatedContentList;
  }

  formatDescription(description) {
    if (description) {
      description = description.replace(/<p>|<\/p>/g, '');
      description = applyMarkdown(description);
    }
    return description;
  }

  submitReplyClick(comment) {

    this.setState({
      replyError: false,
    });

    const replyText = this.state.replyTextBoxInput;

    if (replyText.length > 1024) {
      this.setState({
        replyError: true,
      });
    } else if (replyText.length > 0) {
      const reply = {
        comment: encodeURI(replyText),
      };

      const index = this.getIndex(comment.createdTimestamp);
      const { comments } = this.state;
      comment.activeReply = !comment.activeReply;

      this.addReply(comment.articleId, comment.createdTimestamp, reply)
        .then((data) => {
          if (!comment.reply) {
            comment.reply = [];
          }
          comment.reply.push(data[':reply'][0]);
          comments[index] = comment;
          this.setState({
            comments,
            replyTextBoxInput: ''
          });
        });
    }
  }

  submitCommentClick() {
    this.setState({
      commentError: false,
    });

    const commentText = this.state.textBoxInput;

    const articleId = `${this.state.id}_${getLocale()}`;
    const minCommentLength = this.state.minCommentLength;

    if (commentText.length > 1024 || commentText.length < minCommentLength) {
      this.setState({
        commentError: true,
        commentErrorMsg: commentText.length > 1024 ? 'Error: Comments must be under 1024 characters.' : `Error: Comment must be over ${minCommentLength} characters.`
      });
    } else if (commentText.length > 0) {
      this.clearTextBox();

      const newComment = {
        articleId,
        comment: encodeURI(commentText),
        isPromoted: false,
      };

      this.addComment(articleId, newComment)
        .then((data) => {
          const { comments } = this.state;
          comments.unshift(data);
          this.setState({
            comments,
          });
        });
        postSubmitArticleCommentAction(window.location.href);
    }
    this.sendActionAnalytics('Comment', 'Submit Comment');
  }

  activeDeleteClick(comment) {
    comment.activeDelete = !comment.activeDelete;

    const index = this.getIndex(comment.createdTimestamp);
    const { comments } = this.state;
    comments[index] = comment;

    this.setState({
      comments,
    });
  }

  deleteCommentClick(comment) {
    const index = this.getIndex(comment.createdTimestamp);
    const { comments } = this.state;

    if (comment.isPromoted) {
      this.unpromoteCommentClick(comment);
    }
    this.deleteComment(comment.articleId, comment.createdTimestamp)
      .then((data) => {
        comments.splice(index, 1);
        this.setState({
          comments,
        });
      });
  }

  unpromoteCommentBeforeDelete(comment) {
    getComment(comment.articleId, comment.createdTimestamp)
      .then((commentData) => {
        if (commentData.isPromoted) {
          const unpromotedComment = this.generateUnpromotedComment(commentData);
          // this.updateCommentRecord(unpromotedComment);
          this.updateArticleRecord(unpromotedComment);
        } else {
          console.log('Comment is already unpromoted... Updating comments list');
          this.updateCommentRecord(commentData);
          this.refreshPromotedComments();
        }
      }).catch((error) => {
        this.updateArticleRecord(comment);
        console.log(`Error getting comment: ${error}`);
      });
  }


  activePromoteClick(comment) {
    comment.activePromote = !comment.activePromote;

    const index = this.getIndex(comment.createdTimestamp);
    const { comments } = this.state;
    comments[index] = comment;

    this.setState({
      comments,
    });
  }

  updateCommentRecord(comment) {
    const { comments } = this.state;
    const index = this.getIndex(comment.createdTimestamp);

    this.updateComment(comment.articleId, comment)
      .then((updatedComment) => {
        comments.splice(index, 1, updatedComment);
        this.setState({
          comments,
        });
      });
  }

  updateArticleRecord(comment) {
    getArticle(this.state.article.id)
      .then((article) => {
        if (!comment.isPromoted) {
          const index = this.getPromotedIndex(comment.createdTimestamp);
          article.promotedComments.splice(index, 1);
        } else {
          article.promotedComments.unshift(comment);
        }
        this.updateArticle(article)
          .then((articleData) => {
            const parsedContent = typeof articleData.content === 'string' ? JSON.parse(articleData.content) : articleData.content;
            this.setState({
              id: articleData.id,
              article: articleData,
              date: articleData.createdDate,
              title: articleData.title,
              content: parsedContent,
              files: this.getFiles(parsedContent),
              tags: (articleData.tags && typeof articleData.tags === 'string') ? JSON.parse(articleData.tags) : articleData.tags,
              promotedComments: articleData.promotedComments.sort(this.comparePromotedTimestamp),
              description: articleData.description,
              languageOverride: articleData.displayOverrideText,
            });
          }).catch((error) => {
            console.log(`Error occured updating the Article:${error}`);
          });
      }).catch((error) => {
        console.log(`Error getting article: ${error}`);
      });
  }

  generatePromotedComment(comment) {
    const promoteDate = new Date().toISOString();
    const promotedComment = {
      articleId: comment.articleId,
      createdTimestamp: comment.createdTimestamp,
      comment: comment.comment,
      createdDate: comment.createdDate,
      isPromoted: true,
      region: comment.region,
      userDealership: comment.userDealership,
      userFirstName: comment.userFirstName,
      userId: comment.userId,
      userLastName: comment.userLastName,
      userRole: comment.userRole,
      userImage: comment.userImage,
      promoteDate,
      reply: comment.reply ? comment.reply : [],
    };
    return promotedComment;
  }

  generateUnpromotedComment(comment) {
    const unpromotedComment = {
      articleId: comment.articleId,
      createdTimestamp: comment.createdTimestamp,
      comment: comment.comment,
      createdDate: comment.createdDate,
      isPromoted: false,
      region: comment.region,
      userDealership: comment.userDealership,
      userFirstName: comment.userFirstName,
      userId: comment.userId,
      userLastName: comment.userLastName,
      userRole: comment.userRole,
      userImage: comment.userImage,
      reply: comment.reply ? comment.reply : [],
    };

    return unpromotedComment;
  }

  refreshPromotedComments() {
    getArticle(this.state.id)
      .then((article) => {
        this.setState({
          article,
          promotedComments: article.promotedComments.sort(this.comparePromotedTimestamp),
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  promoteCommentClick(comment) {
    getComment(comment.articleId, comment.createdTimestamp)
      .then((commentData) => {
        if (!commentData.isPromoted) {
          const promotedComment = this.generatePromotedComment(commentData);
          this.updateCommentRecord(promotedComment);
          this.updateArticleRecord(promotedComment);
        } else {
          console.log('Comment is already promoted... Updating comments list');
          this.updateCommentRecord(commentData);
          this.refreshPromotedComments();
        }
      }).catch((error) => {
        console.log(`Error getting comment: ${error}`);
      });
  }

  unpromoteCommentClick(comment) {
    getComment(comment.articleId, comment.createdTimestamp)
      .then((commentData) => {
        if (commentData.isPromoted) {
          const unpromotedComment = this.generateUnpromotedComment(commentData);
          this.updateCommentRecord(unpromotedComment);
          this.updateArticleRecord(unpromotedComment);
        } else {
          console.log('Comment is already unpromoted... Updating comments list');
          this.updateCommentRecord(commentData);
          this.refreshPromotedComments();
        }
      }).catch((error) => {
        this.updateArticleRecord(comment);
        console.log(`Error getting comment: ${error}`);
      });
  }

  activeUnpromoteClick(comment) {
    comment.activeUnpromote = !comment.activeUnpromote;

    const index = this.getPromotedIndex(comment.promotedDate);
    const comments = this.state.promotedComments;
    comments[index] = comment;

    this.setState({
      promotedComments: comments,
    });
  }

  getFiles(content) {
    try {
      const files = [];
      for (const row in content) {
        if (content[row].contentType === 'Link') {
          files.push(content[row].contentType);
        }
      }
      return files;
    } catch (err) {
      console.log(err);
    }
  }

  updateTextBoxInputValue(input) {
    this.setState({
      textBoxInput: input,
    });
  }

  updateReplyTextBoxInputValue(input) {
    this.setState({
      replyTextBoxInput: input,
    });
  }

  displayReplyError(errorText) {
    this.setState({
      replyError: `Error: ${errorText}`,
    });
  }

  clearTextBox() {
    this.setState({
      textBoxInput: '',
    });
  }

  getCleanedArticleId(articleId) {
    if (articleId.includes('article?')) {
      articleId = articleId.split('article?')[1];
    }
    if (articleId.includes('~')) {
      const index = articleId.lastIndexOf('~');
      articleId = articleId.substring(index + 1);
    }
    articleId = articleId.split('&')[0];
    return articleId;
  }

  componentWillMount() {
    const path = window.location.href.split('/');
    const articleId = this.getCleanedArticleId(path[path.length - 1]);
    this.loadArticles(articleId)
      .then((value) => {
        if (value.noResponse || !value) {
          this.setState({ noResponse: value.noResponse });
        } else {
          postUserActivityRecord(window.location.pathname, false, value.title);

          const parsedContent = typeof value.content === 'string' ? JSON.parse(value.content) : value.content;
          this.setState({
            id: articleId,
            article: value,
            date: value.createdDate,
            title: value.title,
            content: parsedContent,
            files: this.getFiles(parsedContent),
            likes: value.likes ? value.likes : 0,
            dislikes: value.dislikes,
            tags: typeof value.tags === 'string' ? JSON.parse(value.tags) : value.tags,
            type: value.type,
            promotedComments: value.promotedComments.sort(this.comparePromotedTimestamp),
            description: value.description,
            languageOverride: value.displayOverrideText,
            minCommentLength: 0,
          });

          this.sendLoadAnayltics();
          postViewArticleAction(window.location.href);
          this.loadComments(articleId)
            .then((commentValue) => {
              const comments = commentValue.Items;
              const lastKey = commentValue.LastEvaluatedKey;
              comments.sort(this.compareTimestamp);
              this.setState({
                comments,
                lastKey,
              });
            }).catch((err) => {
              console.log(err);
            });
          this.getRelatedArticles(articleId);
          this.getMinCommentLength(articleId);
        }
      })
      .then(() => {
        const { sendArticleContentLinkClickAnalytics } = this;
        $('div#articleContent a').click((e) => {
          const label = $(e.target).text();
          sendArticleContentLinkClickAnalytics(label);
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  render() {
    return ArticleTemplate(this);
  }
}
